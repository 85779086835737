import "./Imprint.css";
import Header from "../components/Header";

function Imprint() {
    return (
        <>
            <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="w-m flex column gap">
                <h1 className="title">Impressum</h1>
                <p>Diese Webseite wird weder geschäftlich noch kommerziell genutzt. Deswegen ist kein Impressum erforderlich.</p>
                <p>E-Mail: <a href="mailto:contact@roomofhorror.de">contact@roomofhorror.de</a></p>
            </div>
        </>
    );
};

export default Imprint;
import "./Footer.css";
import Logo from "../assets/logo/logo_normal.png";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="footer relative w-100 flex column">
            <svg viewBox="0 0 1440 100">
                <path fillOpacity="1" d="M0,32L60,32C120,32,240,32,360,42.7C480,53,600,75,720,85.3C840,96,960,96,1080,85.3C1200,75,1320,53,1380,42.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z" />
            </svg>
            
            <div className="flex center items-center pb-5 pt-5">
                <div className="footer-container"> {/* width from App.css */}
                    <div className="w-m flex column gap-3">
                        <p>Die Room of Horror Anwendung wurde im Rahmen einer Praxisbachelorarbeit in Zusammenarbeit mit dem Bundeswehrkrankenhaus Berlin und der Hochschule für Wirtschaft und Recht Berlin erstellt unter Betreuung von Prof. Dr. rer. Heike Wiesner. Ziel der Anwendung ist es, eine Trainingsumgebung für auszubildende Arbeitskräfte im Bundeswehrkrankenhaus zu schaffen, in der Unstimmigkeiten in einem Krankenzimmer (Room of Horror) gefunden werden müssen. Zur Entwicklung wurden die Unity Gameengine, Express als API und React im Frontend verwendet.</p>
                        
                        <div className="flex between wrap gap-3">
                            <ul>
                                <li>
                                    <Link to="/imprint">
                                        <p>Impressum</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms">
                                        <p>AGBs</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy">
                                        <p>Datenschutz</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cookies">
                                        <p>Cookies</p>
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="/imprint">
                                        <p>Kontakt</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        <p>Support</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        <p>FAQ</p>
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="https://www.hwr-berlin.de/">
                                        <p>HWR Berlin</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.bwkrankenhaus.de/de/bundeswehrkrankenhaus-berlin">
                                        <p>BWK Berlin</p>
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link to="/docs">
                                        <p>Dokumentation</p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/github">
                                        <p>GitHub</p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="w-100 between items-center flex mt-3 gap wrap">
                            <Link to="/">
                                <img src={Logo} alt="logo" width={40} height={40} />
                            </Link>
                            <p>Kâan Turan, Mohammed El-Bachir | SS 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
import React, { forwardRef, ChangeEvent, useState } from "react";
import "./Input.css";
import InfoCircle from "./InfoCircle";

interface InputProps {
    title?: string;
    infoText?: string;
    error?: string;
    fullWidth?: boolean;
    preselect?: string;

    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;

    className?: string;
    [key: string]: any;
}

function Input(props: InputProps, ref: React.Ref<HTMLInputElement>) {
    const { title, infoText, fullWidth, error, onChange, preselect, className, ...overflowProps } = props;

    const [value, setValue] = useState(preselect === undefined ? "" : preselect);

    function handleChange(event: any) {
        const userInput = event.target.value;

        setValue(userInput);
        
        if (onChange) { //additional onChange event
            onChange(event);
        };
    };

    return (
        <div className={ `input flex column gap-1 ${className || ""}` }>
            <div className="flex gap-1 items-center">
                <p>{ title }</p>
                { infoText && <InfoCircle text={ infoText } /> }
            </div>
            
            <input
                ref={ ref }
                className={`
                    ${ error ? "error" : "" }
                    ${ fullWidth ? "full-width" : "" }
                `}
                    
                onChange={handleChange}
                value={value}

                { ...overflowProps }
            />

            { error && <p className="wrong-text">{ error }</p> }
        </div>
    );
}

/**
 * input component
 * @param title title text
 * @param infoText info text
 * @param error error text
 * @param fullWidth make component full width
 * @param preselect preset value (e. g. for edit mode)
 * @param onChange on input field change
 * @returns input component
 */
export default forwardRef(Input);
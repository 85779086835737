import "./Button.css";
import { Link } from "react-router-dom";
import { warningLog } from "../utils/ConsoleLog";
import LoadingSymbol from "./LoadingSymbol";

/**
 * button
 * @param title title
 * @param to link to address (local and external)
 * @param type purple (default), black, text-only, transparent, gray, blue-border, green, red
 * @param big big version (disabled by default)
 * @param fullWidth make width 100% (disabled by default)
 * @param loading loading animation on button (clicking will be disabled while loading is active)
 * @param nowrap prevent line break
 * @return button component
 */
function Button(
    props: Readonly<{
        title: string,
        to?: string,
        color?: string,
        big?: boolean,
        fullWidth?: boolean,
        nowrap?: boolean,
        loading?: boolean,

        className?: string,
        [key: string]: any
    }>
) {
    const {title, to, type, big, fullWidth, nowrap, loading, className, ...overflowProps} = props;

    return (
        to === undefined ?
            getButton()
        :
            <Link to={to} style={{textDecoration: "none"}}>
                {getButton()}
            </Link>
    )
    
    function getButton() {
        let buttonType = "";
        switch (type) {
            case undefined: break;
            case "purple": break; //default
            case "black": buttonType = "black"; break;
            case "text-only": buttonType = "text-only"; break;
            case "transparent": buttonType = "transparent"; break;
            case "blue-border": buttonType = "blue-border"; break;
            case "gray": buttonType = "gray"; break;
            case "green": buttonType = "green"; break;
            case "red": buttonType = "red"; break;
            default: warningLog(`Button color ${type} unavailable`, "Available options (case-sensitive): purple, black, text-only, transparent, blue-border, green, red"); break;
        }

        return (
            <button
                className={`
                    ${buttonType}
                    ${big ? "big" : ""}
                    ${fullWidth ? "full-width" : ""}
                    ${nowrap ? "nowrap" : ""}
                    ${loading ? "loading" : ""}
                    ${className || ""}
                `}

                {...overflowProps}
            >
                {getTitle()}

                <div className="loading-container">
                    {loading && <LoadingSymbol />}
                </div>
            </button>
        )
    }

    function getTitle() {
        if (big) {
            return <h2 className={loading ? "vanish" : ""}>{title}</h2>;
        }
        return <p className={loading ? "vanish" : ""}>{title}</p>;
    }
}

export default Button;
import axios from "axios";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { setAccessToken } from "../utils/WebStorage";
import { getBackendOrigin } from "../utils/StringGenerator";

/**
 * join game request
 * @param token token
 * @param gameCode game cdoe
 * @returns status code
 */
export async function joinGameService(token: string, gameCode: string): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };

        const params = new URLSearchParams({
            gameCode
        });

        const url = `${getBackendOrigin()}/game/join-game?${params.toString()}`;

        const data = await axios.post(url, null, { headers });

        if (getStatusCode(data) === 200) {
            setAccessToken(data.data.accessToken);
        }

        return data;
    } catch(error) {
        return error;
    }
};

/**
 * edit game
 * @param token token
 * @param target column name (status, errors, uuid (signout only possible) etc.)
 * @param value value to change
 * @returns status code
 */
export async function editGameService(token: string, target: string, value: any): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };

        const params = new URLSearchParams({
            target,
            value
        });
        
        const data = await axios.put(`${getBackendOrigin()}/game/edit-game?${params.toString()}`, {}, { headers });
        
        if (getStatusCode(data) === 200) { //set new access token
            setAccessToken(data.data.accessToken);
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * get game data
 * @param token token
 * @returns status code & data
 */
export async function getGameService(token: string): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };
        
        const data = await axios.get(`${getBackendOrigin()}/game/get-game`, { headers });
        
        if (getStatusCode(data) === 200) { //set new access token
            setAccessToken(data.data.accessToken);
        };

        return data;
    } catch(error) {
        return error;
    };
};
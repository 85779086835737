import Lottie from "react-lottie";
import Button from "./Button";
import "./GameEndBanner.css";
import ConfettiAnimation from "../assets/lotti/confetti.json";

/**
 * game end banner component
 * @param won if game won
 * @param newGameClick if clicked on new game button
 * @return game end banner component
 */
function GameEndBanner(
    props: Readonly<{
        won: boolean,
        newGameClick?: (click: true) => void,

        className?: string,
        [key: string]: any
    }>
) {
    const {won, newGameClick, className, ...overflowProps} = props;

    const animationOptions = {
        animationData: ConfettiAnimation,
        loop: false,
        autoplay: true
    };
    
    return (
        <>
            { won &&
                <div className="lotti-confetti">
                    <Lottie options={animationOptions} isClickToPauseDisabled={true} />
                </div>
            }
            
            <div className={`game-end-banner ${!won && "lost"} ${className || ""}`} {...overflowProps}>
                <div className="flex column gap">
                    <h1>{won ? "Bestanden" : "Nicht bestanden"}</h1>
                    <p>{won ? "Alle Unstimmigkeiten wurden vom Spieler gefunden!" : "Zu viele Fehlklicks wurden durch den Spieler "}</p>
                </div>
                <div className="w-100 flex center">
                    <Button title="NEUES SPIEL" big onClick={newGameClick} />
                </div>
            </div>
        </>
    )
}

export default GameEndBanner;
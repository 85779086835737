import axios from "axios";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { setAccessToken, setLastRequest } from "../utils/WebStorage";
import { getBackendOrigin } from "../utils/StringGenerator";

/**
 * call signup api
 * @param email email 
 * @param givenName givenName
 * @param surname surname
 * @param address address
 * @returns data
 */
export async function signupService(email: string, givenName: string, surname: string, address: string): Promise<any> {
    try {
        const params = new URLSearchParams({
            email,
            givenName,
            surname,
            address
        });
        
        const data = await axios.post(`${getBackendOrigin()}/account/signup?${params.toString()}`);
        
        if (getStatusCode(data) === 200) { //not nessesary but, so there is a slight delay on the success page
            setLastRequest();
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call login api
 * @param email email 
 * @returns data
 */
export async function loginService(email: string): Promise<any> {
    try {
        const params = new URLSearchParams({
            email
        });
        
        const data = await axios.post(`${getBackendOrigin()}/account/login?${params.toString()}`);

        if (getStatusCode(data) === 200) { //set last request
            setLastRequest();
        };
        
        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call get auth api
 * @param token email 
 * @returns data
 */
export async function authService(token: string): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };
        
        const data = await axios.get(`${getBackendOrigin()}/account/auth`, { headers });
        
        if (getStatusCode(data) === 200) { //set new access token
            setAccessToken(data.data.accessToken);
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call recovery api
 * @param email email 
 * @returns data
 */
export async function getRecoveryService(email: string): Promise<any> {
    try {
        const params = new URLSearchParams({
            email
        });
        
        const data = await axios.post(`${getBackendOrigin()}/account/get-recovery?${params.toString()}`);
        
        if (getStatusCode(data) === 200) { //set last request
            setLastRequest();
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call set auth api
 * @param token email 
 * @returns data
 */
export async function setRecoveryService(token: string): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };
        
        const data = await axios.get(`${getBackendOrigin()}/account/set-recovery`, { headers });
        
        if (getStatusCode(data) === 200) { //set new access token
            setAccessToken(data.data.accessToken);
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call retrieve api
 * @param token token 
 * @param type data type
 * @returns data
 */
export async function retrieveService(token: string, type: string): Promise<any> {
    try {
        const headers = {
            "Authorization": token
        };

        const params = new URLSearchParams({
            type
        });
        
        const data = await axios.get(`${getBackendOrigin()}/account/retrieve`, { headers, params });
        
        if (getStatusCode(data) === 200) { //set new access token
            setAccessToken(data.data.accessToken);
        };

        return data;
    } catch(error) {
        return error;
    };
};

/**
 * call session id api
 * @param email email 
 * @returns data
 */
export async function sessionIDService(sessionID: string): Promise<any> {
    try {
        const params = new URLSearchParams({
            sessionID
        });
        
        const data = await axios.get(`${getBackendOrigin()}/account/use-session-id?${params.toString()}`);
        
        if (getStatusCode(data) === 200) { //set last request
            setAccessToken(data.data.accessToken);
        };
        
        return data;
    } catch(error) {
        return error;
    };
};
import "./Privacy.css";
import Header from "../components/Header";

function Privacy() {
    return (
        <>
            <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="w-m flex column gap">
                <h1 className="title">Datenschutz</h1>
                <p>Auf dieser Webseite werden Cookies ausschließlich verwendet, um die technische Funktionalität sicherzustellen. Alle Angaben werden verschlüsselt auf unserem Server gespeichert. Wir geben keine Daten an Dritte weiter; sie werden nur genutzt, um den reibungslosen technischen Betrieb der Webseite/Anwendung zu gewährleisten.</p>
            </div>
        </>
    );
};

export default Privacy;
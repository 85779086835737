import "./Login.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { loginService } from "../services/account.service";
import { isEmail } from "../utils/InputCheckHelper";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import ContentContainer from "../components/ContentContainer";
import { changeBodyColor } from "../utils/Background";

function Login() {
    const navigate = useNavigate();

    changeBodyColor("#fdfdfd");
    
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [generalError, setGeneralError] = useState("");

    const [loading, setLoading] = useState(false);

    async function handleButtonClick() { //login button click
        setLoading(true);
        
        if (validate(email) !== false) {
            const request = await loginService(email);
            const statusCode = getStatusCode(request);
            processStatus(statusCode, statusCode === 200 ? request.data.sessionID : "");
        };

        setLoading(false);
    };

    return (
        <>  
            <Header title="Anmeldung" bigLogo={false} buttonTo="/signup" buttonText="Registrieren" buttonClick={() => {}} />

            <div className="login w-s mt-4">
                <ContentContainer className="flex column gap">
                    <Input
                        title="E-Mail-Adresse"
                        placeholder="Deine E-Mail-Adresse"
                        infoText="Bitte trage deine E-Mail-Adresse ein, um fortzufahren"
                        error={emailError}
                        onChange={(res) => {
                            setEmail(res.target.value);
                            !isEmail(email) && validate(res.target.value);
                        }}
                        onBlur={(res) => validate(res.target.value)}
                        onKeyDown={(res) => { //on enter click
                            if (res.key === "Enter" && validate(res.target.value) === true) {
                                handleButtonClick();
                            };
                        }}
                        fullWidth
                    />

                    <div className="flex gap-1 column">
                        <div className="flex gap between">
                            <p>
                                <Link to="/faq">Probleme bei der Anmeldung?</Link>
                            </p>
                            <p className="error text-right">{generalError}</p>
                        </div>

                        <Button title="Anmelden" onClick={() => handleButtonClick()} loading={loading} fullWidth big />
                    </div>

                    <p>Bitte gib deine E-Mail-Adresse ein, um dich anzumelden. Falls du noch kein Benutzerkonto hast, kannst du dir ein kostenloses <Link to="/signup">erstellen</Link>. Sollte dir der Kontozugriff entzogen worden sein, kannst du dein Konto <Link to="/recovery">wiederherstellen</Link>.</p>
                </ContentContainer>
            </div>
        </>
    );

    function validate(email: string): boolean {
        setGeneralError("");
        setEmailError("");

        const checkedEmail = isEmail(email);

        if (checkedEmail !== null) {
            setEmailError(checkedEmail);
            return false;
        } else {
            setEmail(email);
            return true;
        };
    };

    function processStatus(status: number, sessionID: string) {
        switch (status) {
            case 200: navigate(`/success?type=login&email=${email}&session_id=${sessionID}`); break; //success
            case 400: navigate(`/errorpage?code=500`); break; //(request) input issues (should not happen)
            case 404: setEmailError("Ein Konto mit dieser E-Mail-Adresse existiert nicht"); break; //not found
            case 429: navigate("/errorpage?code=429"); break; //too many requests
            case 500: navigate("/errorpage?code=500"); break; //internal error on backend
            case 504: navigate("/errorpage?code=504"); break; //backend not reachable
            default: navigate("/errorpage?code=500"); break;
        };
    };
};

export default Login;
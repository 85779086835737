import "./Signup.css";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { signupService } from "../services/account.service";
import { isEmail, isName, isAddress } from "../utils/InputCheckHelper";
import Header from "../components/Header";
import Button from "../components/Button";
import Input from "../components/Input";
import Play from "../assets/overflow/play_button_dark.png";
import VR from "../assets/overflow/vr_glasses_dark.png";
import Pen from "../assets/overflow/pen_dark.png";
import ContentContainer from "../components/ContentContainer";
import { changeBodyColor } from "../utils/Background";

function Signup() {
    const navigate = useNavigate();

    changeBodyColor("#fdfdfd");
    
    const [userInput, setUserInput] = useState({
        email: "",
        givenName: "",
        surname: "",
        address: ""
    });

    const [inputError, setInputError] = useState({
        general: "",

        email: "",
        givenName: "",
        surname: "",
        address: ""
    });

    const [loading, setLoading] = useState(false);

    async function handleButtonClick() { //signup button click
        setLoading(true);
        
        if (validate(isEmail, userInput.email, "email") !== false &&
            validate(isName, userInput.givenName, "givenName") !== false &&
            validate(isName, userInput.surname, "surname") !== false &&
            validate(isAddress, userInput.address, "address")) {

            const request = await signupService(userInput.email, userInput.givenName, userInput.surname, userInput.address);
            const statusCode = getStatusCode(request);
            
            processStatus(statusCode, statusCode === 200 ? request.data.sessionID : "");
        } else {
            //to show all possible errors
            validate(isEmail, userInput.email, "email");
            validate(isName, userInput.givenName, "givenName");
            validate(isName, userInput.surname, "surname");
            validate(isAddress, userInput.address, "address");
        };

        setLoading(false);
    };

    const inputRefGivenName = useRef(null);
    const inputRefSurname = useRef(null);
    const inputRefCompanyName = useRef(null);
    const inputRefAddress = useRef(null);

    /**
     * input field enter-key press handler
     * @param event event
     * @param nextRef next input field to select on enter-key press
     */
    function handleEnterPress(event: React.KeyboardEvent<HTMLInputElement>, nextRef: string) {
        if (event.key === "Enter") {
            switch (nextRef) {
                case "givenName": inputRefGivenName.current.focus(); break;
                case "surname": inputRefSurname.current.focus(); break;
                case "companyName": inputRefCompanyName.current.focus(); break;
                case "address": inputRefAddress.current.focus(); break;
                default: handleButtonClick();
            };
        };
    };

    return (
        <>
            <Header title="Registrierung" bigLogo={false} buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="signup w-m flex gap-3 between wrap">
                <div className="flex column gap-3 flex-1">
                    <ContentContainer className="flex gap-3 row items-center" color="#f2f2f2">
                        <img src={Pen} width="50px" alt="info" />
                        <h2 className="tutorial-title">Konto erstellen</h2>
                    </ContentContainer>

                    <ContentContainer className="flex gap-3 row items-center" color="#f2f2f2">
                        <img src={VR} width="50px" alt="info" />
                        <h2 className="tutorial-title">VR-Brille verbinden</h2>
                    </ContentContainer>

                    <ContentContainer className="flex gap-3 row items-center" color="#f2f2f2">
                        <img src={Play} width="50px" alt="info" />
                        <h2 className="tutorial-title">Spiel starten</h2>
                    </ContentContainer>

                    <div className="flex center">
                        <Button title="Erfahre mehr" type="transparent" to="/faq" />
                        <Button title="Noch fragen?" type="text-only" to="/imprint" />
                    </div>
                </div>

                <ContentContainer className="flex column flex-1 gap">
                    <Input
                        title="E-Mail-Adresse"
                        infoText="Diese E-Mail-Adresse dient der zukünftigen Anmeldung und ist nach der Erstellung des Kontos nicht öffentlich einsehbar"
                        error={inputError.email}
                        placeholder="Deine E-Mail-Adresse"
                        onChange={(res: any) => {
                            setUserInput(prevState => ({ ...prevState, email: res.target.value }));
                            !isEmail(res.target.value) && validate(isEmail, res.target.value, "email");
                        }}
                        onBlur={(res: any) => validate(isEmail, res.target.value, "email")}
                        onKeyDown={(event: any) => handleEnterPress(event, "givenName")}
                        fullWidth
                    />
                    <Input
                        title="Vorname"
                        error={inputError.givenName}
                        placeholder="Dein Vorname"
                        onChange={(res: any) => {
                            setUserInput(prevState => ({ ...prevState, givenName: res.target.value }));
                            !isName(res.target.value) && validate(isName, res.target.value, "givenName");
                        }}
                        onBlur={(res: any) => validate(isName, res.target.value, "givenName")}
                        onKeyDown={(event: any) => handleEnterPress(event, "surname")}
                        ref={inputRefGivenName}
                        fullWidth
                    />
                    <Input
                        title="Nachname"
                        error={inputError.surname}
                        placeholder="Dein Nachname"
                        onChange={(res: any) => {
                            setUserInput(prevState => ({ ...prevState, surname: res.target.value }));
                            !isName(res.target.value) && validate(isName, res.target.value, "surname");
                        }}
                        onBlur={(res: any) => validate(isName, res.target.value, "surname")}
                        onKeyDown={(event: any) => handleEnterPress(event, "address")}
                        ref={inputRefSurname}
                        fullWidth
                    />
                    <Input
                        title="Adresse"
                        placeholder="Deine Adresse"
                        error={inputError.address}
                        onChange={(res: any) => {
                            setUserInput(prevState => ({ ...prevState, address: res.target.value }));
                            !isAddress(res.target.value) && validate(isAddress, res.target.value, "address");
                        }}
                        onBlur={(res: any) => validate(isAddress, res.target.value, "address")}
                        onKeyDown={(event: any) => handleEnterPress(event, "")}
                        ref={inputRefAddress}
                        fullWidth
                    />

                    <p className="error text-right">{inputError.general}</p>

                    <div>
                        <Button title="Konto erstellen" onClick={() => handleButtonClick()} big fullWidth loading={loading} />
                        <Button title="Du besitzt bereits ein Konto?" type="text-only" to="/login" big fullWidth />
                    </div>

                    <p>Indem Sie auf "Konto erstellen" drücken erklären Sie sich damit einverstanden, unsere <Link to="/terms">Allgemeinen Geschäftsbedingungen</Link> zu akzeptieren und bestätigen das Sie unsere <Link to="/privacy">Datenschutzerklärung</Link> gelesen haben.</p>
                </ContentContainer>
            </div>
        </>
    );

    /**
     * value validation
     * @param checkFunction function to validate value (e. g. isEmail, isName, !isSurname etc.)
     * @param value value to check (e. g. test@domain.com etc.)
     * @param type value type to check (e. g. email, name, surname etc.)
     */
    function validate(checkFunction: any, value: string, type: string): boolean {
        const error = checkFunction(value);

        if (error === null && value !== "") {
            switch (type) {
                case "email": setInputError(prevState => ({ ...prevState, email: "" })); break;
                case "givenName": setInputError(prevState => ({ ...prevState, givenName: "" })); break;
                case "surname": setInputError(prevState => ({ ...prevState, surname: "" })); break;
                case "address": setInputError(prevState => ({ ...prevState, address: "" })); break;
            };
            return true;
        } else {
            switch (type) {
                case "email": setInputError(prevState => ({ ...prevState, email: error })); break;
                case "givenName": setInputError(prevState => ({ ...prevState, givenName: error })); break;
                case "surname": setInputError(prevState => ({ ...prevState, surname: error })); break;
                case "address": setInputError(prevState => ({ ...prevState, address: error })); break;
            };
            return false;
        };
    };

    /**
     * next step after api response
     * @param status status code
     */
    function processStatus(status: number, sessionID: string) {
        switch (status) {
            case 200: navigate(`/success?type=signup&email=${userInput.email}&session_id=${sessionID}`); break; //success
            case 409: setInputError(prevState => ({ ...prevState, email: "Ein Konto mit dieser E-Mail-Adresse existiert bereits" })); break; //conflict (account exists already)
            case 400: navigate("/errorpage?code=500"); break; //(request) input issues (should not happen)
            case 429: navigate("/errorpage?code=429"); break; //too many requests
            case 500: navigate("/errorpage?code=500"); break; //internal error
            case 504: navigate("/errorpage?code=504"); break; //network error
            default: navigate("/errorpage?code=500"); break;
        };
    };
};

export default Signup;
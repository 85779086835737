import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { AccessNeeded, RedirectOnAccess } from "./utils/Permission";
import { getCookiePreferenceChoosed } from "./utils/WebStorage";
import Success from "./pages/Success";
import Footer from "./components/Footer";
import ErrorPage from "./pages/ErrorPage";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import Recovery from "./pages/Recovery";
import Cookies from "./components/Cookies";
import Settings from "./pages/Settings";
import { ScrollToTopOnReload } from "./utils/ScrollHelper";
import Maintenance from "./pages/Maintenance";
import "./styles/colors.css";
import "./styles/layout.css";
import "./styles/texts.css";
import "./styles/mobile.css";
import "./App.css";
import Imprint from "./pages/Imprint";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import FAQ from "./pages/FAQ";
import Home from "./pages/Home";

function App() {
    /**
     * page layout function
     * @param page page content
     * @param footer should the footer be visible?
     * @param cookies can you view the page without choosing a cookie preference?
     * @returns page layout
     */
    function usePageLayout(
        props: Readonly<{
            page: any,
            footer?: boolean,
            cookies?: boolean,
        }>
    ) {
        const { page, footer, cookies } = props;
        
        return <>
            { !getCookiePreferenceChoosed() && (cookies === true || cookies === undefined) && <Cookies /> } {/* cookie message */}
            
            { process.env.REACT_APP_MAINTENANCE === "true" ?
                <Maintenance /> : <>
                    <div className="body-container"> {/* so the footer stays down -- even on a empty page */}
                        {page}
                    </div>
                    {footer && <Footer />}
                </>
            }
        </>;
    };

    if (process.env.REACT_APP_USE_ENV !== "true") { //if env file is missing
        console.error("\n\n\n>>>     ENV FILE IS MISSING     <<<\n\n\n");
    }

    return (
        <>
            <header>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /> {/* stop iphone auto-zoom in on input/textfields on select */}
            </header>

            <BrowserRouter>
                <ScrollToTopOnReload />

                <Routes>
                    <Route path="/settings" element={<AccessNeeded type="normal">{usePageLayout({ page: <Settings />, footer: false })}</AccessNeeded>} />
                    <Route path="/dashboard" element={<AccessNeeded type="normal">{usePageLayout({ page: <Dashboard />, footer: true })}</AccessNeeded>} />

                    <Route path="/signup" element={<RedirectOnAccess route="/dashboard">{usePageLayout({ page: <Signup />, footer: true })}</RedirectOnAccess>} />
                    <Route path="/login" element={<RedirectOnAccess route="/dashboard">{usePageLayout({ page: <Login />, footer: true })}</RedirectOnAccess>} />
                    
                    <Route path="/recovery" element={usePageLayout({ page: <Recovery />, footer: true })} />
                    <Route path="/success" element={usePageLayout({ page: <Success />, footer: true })} />
                    <Route path="/auth" element={usePageLayout({ page: <Auth />, footer: true })} />
                    <Route path="/errorpage" element={usePageLayout({ page: <ErrorPage/>, footer: true })} />
                    
                    <Route path="/terms" element={usePageLayout({ page: <Terms />, footer: true, cookies: false })} />
                    <Route path="/privacy" element={usePageLayout({ page: <Privacy />, footer: true, cookies: false })} />
                    <Route path="/imprint" element={usePageLayout({ page: <Imprint />, footer: true, cookies: false })} />
                    <Route path="/cookies" element={usePageLayout({ page: <Privacy />, footer: true, cookies: false })} />
                    <Route path="/faq" element={usePageLayout({ page: <FAQ />, footer: true, cookies: false })} />

                    <Route path="/" element={usePageLayout({ page: <Home />, footer: true })} />

                    <Route path="*" element={<Navigate to="/errorpage?code=404" />} /> {/* redirection on wrong input */}
                </Routes>

            </BrowserRouter>
        </>
    );
}

export default App;

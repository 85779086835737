import "./Settings.css";
import { useEffect, useState } from "react";
import { retrieveService } from "../services/account.service";
import { getAccessToken, setAccessToken } from "../utils/WebStorage";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "../assets/overflow/user_profile.png";
import LoadingSymbol from "../components/LoadingSymbol";
import Button from "../components/Button";
import { capitalizeFirstLetter } from "../utils/General";
import WarningModal from "../components/WarningModal";
import Pattern from "../assets/logo/logo_pattern.png";

function Settings() {
    //[]==========[ general ]==========[]
    const navigate = useNavigate();

    const [personalData, setPersonalData] = useState(false);
    const [recovery, setRecovery] = useState(false);
    const [signout, setSignout] = useState(false);

    //[]==========[ fetch data ]==========[]
    const [loading, setLoading] = useState(false);

    const loadingIssueMessage = "Fehler beim laden";
    const [UUID, setUUID] = useState(loadingIssueMessage);
    const [email, setEmail] = useState(loadingIssueMessage);
    const [fullName, setFullName] = useState(loadingIssueMessage);
    const [address, setAddress] = useState(loadingIssueMessage);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const request = await retrieveService(getAccessToken(), "user");
            const statusCode = getStatusCode(request);

            if (statusCode === 200) {
                setUUID(request.data.userUUID)
                setEmail(request.data.email);
                setFullName(capitalizeFirstLetter(request.data.givenName) + " " + capitalizeFirstLetter(request.data.surname));
                setAddress(request.data.address);
            };
            setLoading(false);
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    //[]==========[ settings option component ]==========[]
    function settingsOptionButton(title: string, expand?: boolean, onClick?: any, content?: any, warningModal?: any, arrowSymbol?: boolean) {
        return <div>
            <div
                className={`settings-option ${expand ? "expand" : ""}`}
                style={{ borderRadius: expand ? "10px 10px 0px 0px" : "10px" }}
                onClick={onClick}
            >
                <h2 className="settings-title">{title}</h2>
                <div
                    className="arrow"
                    style={{
                        rotate: expand ? "90deg" : "",
                        transform: `translateX(${expand ? "5px" : ""})`
                    }}
                >
                    {arrowSymbol === undefined || arrowSymbol === true ? <><div /><div /></> : <></>}
                </div>
            </div>

            {expand &&
                <div className="settings-option-content">
                    {content}
                </div>
            }

            {warningModal}
        </div>
    };

    return (
        <>
            <div className="settings w-m flex column gap mt-5" style={{zIndex: 2}}>
                <div className="flex end top">
                    <Button big type="transparent" title="Zurück" onClick={() => navigate("/dashboard")} />
                </div>
                <div className="profile" style={{ justifyContent: `${loading ? "center" : "flex-start"}` }}>
                    {loading ?
                        <LoadingSymbol size={50} /> :
                        <>
                            <img src={ProfilePicture} alt="profile" width="110px" />
                            <div className="flex flex-start w-100 items-center gap-1 wrap">
                                <div className="flex column">
                                    <h2 className="name-title">{fullName}</h2>
                                    <p>{UUID}</p>
                                </div>
                                {/* <h2> </h2> */}
                            </div>
                        </>
                    }
                </div>

                {
                    settingsOptionButton(
                        "Persönliche Daten",
                        personalData,
                        (() => { setPersonalData(!personalData); }),
                        <>
                            <p>Vor- und Nachname: {fullName}</p>
                            <p>Email: {email}</p>
                            <p>Adresse: {address}</p>
                        </>
                    )
                }

                {
                    settingsOptionButton(
                        "Konto Zugriff entziehen",
                        recovery,
                        (() => { setRecovery(!recovery); }),
                        <>
                            <p>Wenn du allen angemeldeten Geräten den Zugriff entziehen möchtest, gehe auf die Wiederherstell-Seite und bestätige deine E-Mail.</p>
                            <div className="flex mt">
                                <Button title="Wiederherstellen" type="gray" onClick={() => navigate("/recovery")} />
                            </div>
                        </>
                    )
                }

                {
                    settingsOptionButton(
                        "Abmelden",
                        false,
                        (() => { setSignout(!signout); }),
                        <></>,
                        <>{signout && <WarningModal title="Jetzt Abmelden?" option1="Abmelden" option2="Abbrechen" choosenOption={(res) => { if (res) { setAccessToken(null); navigate("/"); } else { setSignout(false) } }} />}</>,
                        false
                    )
                }
            </div>

            <div className="settings-pattern">
                <img src={Pattern} alt="pattern" />
            </div>
        </>
    );
};

export default Settings;
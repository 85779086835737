import "./CloseButton.css";

/**
 * close button component
 * @param size button size
 * @param color custom color
 * @return cookie component
 */
function CloseButton(
    props: Readonly<{
        size?: number;
        color?: string;

        className?: string,
        [key: string]: any
    }>
) {
    const { className, size, color, ...overflowProps } = props;
    
    return (
        <div
            className={`close-button ${className || ""}`}
            style={{
                width: size === undefined ? "17px" : String(size + "px"),
                height: size === undefined ? "17px" : String(size + "px")
            }}
            
            {...overflowProps}>
            
            <div style={{
                width: size === undefined ? "20px" : String(size + 2) + "px",
                height: size === undefined ? "3px" : String(size * 0.1) + "px",
                borderRadius: size === undefined ? "2.5px" : String(size * 0.1 / 2 + "px"),
                backgroundColor: color === undefined ? "#212121" : color
            }} />
            <div style={{
                width: size === undefined ? "20px" : String(size + 2) + "px",
                height: size === undefined ? "3px" : String(size * 0.1) + "px",
                borderRadius: size === undefined ? "2.5px" : String(size * 0.1 / 2 + "px"),
                backgroundColor: color === undefined ? "#212121" : color
            }} />
        </div>
    );
}

export default CloseButton;
import Modal from "./Modal";
import { setFirstLogin } from "../utils/WebStorage";
import "./Tour.css";
import Tour1 from "../assets/tour/tour_1.png";
import Tour2 from "../assets/tour/tour_2.png";
import Tour3 from "../assets/tour/tour_3.png";
import Tour4 from "../assets/tour/tour_4.png";
import Tour5 from "../assets/tour/tour_5.png";
import CloseButton from "./CloseButton";
import NextButton from "./NextButton";
import PageBar from "./PageBar";
import { useState } from "react";
import Button from "./Button";

function Tour() {
    const [currentPage, setCurrentPage] = useState(1);

    function handleEndTour() {
        setFirstLogin(false);
        window.location.reload();
    };

    return (
        <Modal width="800px" className="tour items-center gap-3 scroll">
            { currentPage === 1 && page1() }
            { currentPage === 2 && page2() }
            { currentPage === 3 && page3() }
            { currentPage === 4 && page4() }
            { currentPage === 5 ? page5() :
            
                <div className="flex between items-center"> {/* default footer */}
                    { currentPage === 1 ? <div style={{width: "50px"}} /> : <NextButton onClick={() => setCurrentPage(currentPage - 1)} style={{rotate: "180deg"}} /> }
                    <PageBar page={currentPage} totalPages={5} setCurrentPage={setCurrentPage} />
                    <NextButton onClick={() => setCurrentPage(currentPage + 1)} />
                </div>
            }
        </Modal>
    );

    //all tour pages
    function page1() {
        return <>
            <div className="flex between">
                <div style={{width: "17px"}} />
                <img src={Tour1} alt="tour welcome" width="400px" style={{maxWidth: "80%"}} />
                <CloseButton onClick={() => handleEndTour()} />
            </div>
            <div className="flex column items-center gap text-center">
                <h1>Hey, willkommen beim Room of Horror!</h1>
                <h2 className="text-center">Im Folgenden geben wir dir eine kurze Einführung. Wenn du diese nicht benötigst, kannst du sie <span onClick={() => handleEndTour()}>überspringen</span>.</h2>
            </div>
        </>
    }

    function page2() {
        return <>
            <div className="flex between">
                <div style={{width: "17px"}} />
                <img src={Tour2} alt="tour welcome" width="400px" style={{maxWidth: "80%"}} />
                <CloseButton onClick={() => handleEndTour()} />
            </div>
            <h2 className="text-center">Als erstes musst du den Gamecode aus der VR-Anwendung in das Modal eintragen.</h2>
        </>
    }

    function page3() {
        return <>
            <div className="flex between">
                <div style={{width: "17px"}} />
                <img src={Tour3} alt="tour welcome" width="400px" style={{maxWidth: "80%"}} />
                <CloseButton onClick={() => handleEndTour()} />
            </div>
            <h2 className="text-center">Nachdem du den Gamecode eingegeben hast, kannst du die Anzahl der Fehlversuche und die Fehlerkonfiguration anpassen.</h2>
        </>
    }

    function page4() {
        return <>
            <div className="flex between">
                <div style={{width: "17px"}} />
                <img src={Tour4} alt="tour welcome" width="400px" style={{maxWidth: "80%"}} />
                <CloseButton onClick={() => handleEndTour()} />
            </div>
            <h2 className="text-center">Du kannst zwischen individuellen Fehlern und zufälligen Fehlern wählen, jedoch nicht beide gleichzeitig.</h2>
        </>
    }

    function page5() {
        return <>
            <div className="flex between">
                <div style={{width: "17px"}} />
                <img src={Tour5} alt="tour welcome" width="400px" style={{maxWidth: "80%"}} />
                <CloseButton onClick={() => handleEndTour()} />
            </div>
            <h2 className="text-center">Nachdem du das Spiel gestartet hast, wirst du live über den Fortschritt des Spielers informiert. Viel Spaß!</h2>

            <div className="w-100 flex center">
                <Button title="JETZT LOSLEGEN" big onClick={() => handleEndTour()} />
            </div>
        </>
    }
}

export default Tour;
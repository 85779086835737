import "./ErrorPage.css";
import { Link, useLocation } from "react-router-dom";
import ContentContainer from "../components/ContentContainer";
import Face from "../assets/overflow/not_found_face.png"
import Button from "../components/Button";
import { changeBodyColor } from "../utils/Background";
import Header from "../components/Header";

function ErrorPage() {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const code = params.get("code");
    
    changeBodyColor("#fdfdfd");
    
    let message = "Ein Fehler ist aufgetreten, bitte teile uns das mit."; //default message (400)
    switch (code) {
        case "404": message = "Diese Seite exestiert nicht."; break; //not found
        case "429": message = "Du hast zu viele Anfragen verschickst, bitte versuche es später erneut."; break; //too many requests
        case "500": message = "Ein Fehler ist aufgetreten. Falls dies häufiger auftritt, teile uns das bitte mit."; break; //internal error backend
        case "504": message = "Verbindungsfehler, bitte versuche es später erneut."; break; //no response (e. g. backend)
        default: break;
    }

    return (
        <>
            <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="w-s">
                <ContentContainer className="flex column gap">
                    <div className="flex center">
                        <img alt="face" src={Face} width="250px" />
                    </div>
                    
                    <h2 className="text-center">{message}</h2>

                    <div className="flex column gap-1">
                        <Link to="/faq">Du brauchst Hilfe?</Link>
                        <Button title="Zurück zur Startseite" to="/" big fullWidth />
                    </div>
                </ContentContainer>
            </div>
        </>
    );
};

export default ErrorPage;
import "./Success.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { getCurrentDateTime } from "../utils/DateTime";
import { isEmail, isSessionID } from "../utils/InputCheckHelper";
import { loginService, sessionIDService } from "../services/account.service";
import Lottie from "react-lottie";
import Header from "../components/Header";
import SuccessAnimation from "../assets/lotti/success.json";
import InfoCircle from "../components/InfoCircle";
import ContentContainer from "../components/ContentContainer";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { getLastRequest } from "../utils/WebStorage";
import { changeBodyColor } from "../utils/Background";

function Success() {
    const navigate = useNavigate();

    changeBodyColor("#fdfdfd");
    
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const email = params.get("email");
    const sessionID = params.get("session_id");
    const type = params.get("type");

    if (type !== "login" && type !== "signup" && type !== "recovery") {
        navigate("/error?code=500");
    }

    if (email === null || isEmail(email) !== null) { //param issues
        navigate("/error?code=500");
    };

    if ((type === "login" || type === "signup") && (sessionID === null || isSessionID(sessionID))) {
        navigate("/error?code=500");
    };

    const cooldown = Number(process.env.REACT_APP_COOLDOWN);
    const [lastSavedRequest, setLastSavedRequest] = useState(getLastRequest());

    const [timeLeft, setTimeLeft] = useState(Math.floor((lastSavedRequest + cooldown - getCurrentDateTime()) / 1000)); //calculate time left
    const [loading, setLoading] = useState(true);

    const countdownRef = useRef(0);

    useEffect(() => {
        countdownRef.current = Math.floor((lastSavedRequest + cooldown - getCurrentDateTime()) / 1000); //calculate time left

        const interval = setInterval(() => {
            countdownRef.current = countdownRef.current - 1;
            setTimeLeft(countdownRef.current);

            if (countdownRef.current <= 0) { //stop countdown if done
                clearInterval(interval);
            } else {
                setLoading(true);
            };
        }, 1000); //countdown speed

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [lastSavedRequest]);

    async function handleLogin() {
        setLoading(false);
        
        const request = await loginService(email);
        const statusCode = getStatusCode(request);
        processStatus(statusCode);
    };

    useEffect(() => { //auto session id check
        let requestCounter = 0;
        const interval = setInterval(async () => {
            requestCounter++;
            if (requestCounter > Number(process.env.REACT_APP_SESSION_ID_DURATION) / Number(process.env.REACT_APP_SESSION_ID_INTERVAL)) { //stop checking after x time
                clearInterval(interval);
            };

            const request = await sessionIDService(sessionID);

            if (getStatusCode(request) === 200) {
                navigate("/dashboard");
            };
        }, Number(process.env.REACT_APP_SESSION_ID_INTERVAL) * 1000); //every x seconds

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);
    
    const animationOptions = {
        animationData: SuccessAnimation,
        loop: false,
        autoplay: true
    };

    return (
        <>
            <Header onlyTopBar buttonClick={() => {}} />

            <div className="success w-s">
                <ContentContainer className="flex column items-center gap">
                    <div className="lotti-animation">
                        <Lottie options={animationOptions} isClickToPauseDisabled={true} />
                    </div>
                    <div className="flex column gap w-100">
                        <h2 className="message">Bestätigungslink wurde an<br />{email}<br />verschickt</h2>
                        <div className="flex w-100 start items-center gap-1">
                            {loading === true ?
                                <>
                                    {timeLeft > 0 ? (
                                        <p>E-Mail erneut in {timeLeft} Sek. versenden</p>
                                    ) : (
                                        <p className="resend-mail" onClick={() => handleLogin()}>E-Mail erneut versenden</p>
                                    )}

                                </> :
                                <p className="loading">E-Mail erneut versenden</p>
                            }
                            <InfoCircle text={"Manchmal dauert es eine Weile, bis die E-Mail ankommt"} />
                        </div>
                    </div>
                </ContentContainer>
            </div>
        </>
    );

    /**
     * next step after api response
     * @param status status code
     */
    function processStatus(status: number) {
        switch (status) {
            case 200: setLastSavedRequest(getLastRequest()); break; //success
            case 429: navigate(`/errorpage?code=429`); break; //too many requests
            case 400: navigate(`/errorpage?code=500`); break; //(request) input issues (should not happen)
            case 500: navigate("/errorpage?code=500"); break; //internal error
            case 504: navigate("/errorpage?code=504"); break; //network error
            default: navigate("/errorpage?code=500"); break;
        };
    };
}

export default Success;
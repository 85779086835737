import { useNavigate } from "react-router-dom";
import { retrieveService } from "../services/account.service";
import { getStatusCode } from "./DBRequestProcessing";
import { getAccessToken } from "./WebStorage"
import { useEffect } from "react";

/**
 * check if access token in local storage is still valid
 * @returns boolean
 */
export async function accessTokenIsValid(): Promise<boolean> {
    const accessToken = getAccessToken();
    
    if (accessToken === null) {
        return false;
    };

    const request = await retrieveService(accessToken, "check");
    
    if (getStatusCode(request) === 200) {
        return true;
    };

    return false;
};

/**
 * redirect to route if user has access
 * @param children all page components
 * @param route route
 * @returns redirection or not
 */
export const RedirectOnAccess = ({ children, route }) => {
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (await accessTokenIsValid()) {
                navigate(route);
            };
        };
        fetchData();
    }, [navigate, route]);

    return children;
};

/**
 * access needed to visit route
 * @param children all page components
 * @param type type of permission (normal, admin)
 * @returns redirection or not
 */
export const AccessNeeded = ({ children, type }) => {
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (type === "normal") {
                if (!await accessTokenIsValid()) { //if user has no access
                    navigate("/");
                };
            };
            if (type === "admin") {
                
                //not done yet

            };
        };
        fetchData();
    }, [navigate, type]);

    return children;
};
import "./ContentContainer.css";
import { ReactNode } from "react";

/**
 * content container
 * @return content container component
 */
function ContentContainer(
    props: Readonly<{
        fade?: boolean,
        color?: string,

        className?: string,
        children?: ReactNode,
        [key: string]: any
    }>
) {
    const {title, to, fade, big, fullWidth, className, color, children, ...overflowProps} = props;

    return (
        <div className={`content-container ${className || ""}`} style={{backgroundColor: fade === undefined && (color === undefined ? "#EDD8EA" : color), background: fade !== undefined ? "linear-gradient(172deg, rgba(152,127,157,1) 13%, rgba(185,161,191,1) 50%, rgba(197,174,203,1) 91%)" : (color === undefined ? "#EDD8EA" : color)}} {...overflowProps}>
            {children}
        </div>
    )
}

export default ContentContainer;
import { Link } from "react-router-dom";
import { useIsMobile } from "../utils/Mobile";
import Button from "./Button";
import "./Cookies.css";
import { setCookiesPreference } from "../utils/WebStorage";
import Modal from "./Modal";

/**
 * cookie message
 * @return cookie component
 */
function Cookies(
    props: Readonly<{
        className?: string,
        [key: string]: any
    }>
) {
    const { className, ...overflowProps } = props;

    function handleButtonClick(preference: string) {
        setCookiesPreference(preference);
        window.location.reload();
    }
    
    return (
        <Modal width="800px" height="500px" className="gap between" {...overflowProps}>
            <div className="scroll">
                <div className="flex column gap">
                    <h1>Diese Webseite verwendet Cookies</h1>
                    <h2>1 Generelles</h2>
                    <p>Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten von dir (z.B. IP-Adresse), um z.B. Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann auch erst in Folge gesetzter Cookies stattfinden.</p>
                    <h2>2 Widerrufsrecht</h2>
                    <p>Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem du in widersprechen kannst. Du hast das Recht, nicht einzuwilligen und deine Einwilligung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen. Schreibe uns dazu eine E-Mail an <a href="mailto:contact@roomofhorror.de">contact@roomofhorror.de</a>.</p>
                    <h2>3 Funktionalität</h2>
                    <p>Funktionale Services sind notwendig, um über die wesentliche Funktionalität der Website hinausgehende Features wie hübschere Schriftarten, Videowiedergabe oder interaktive Web 2.0-Features bereitzustellen. Inhalte von z.B. Video- und Social Media-Plattformen sind standardmäßig gesperrt und können zugestimmt werden. Wenn dem Service zugestimmt wird, werden diese Inhalte automatisch ohne weitere manuelle Einwilligung geladen.</p>
                    <h2>4 Statistiken</h2>
                    <p>Statistik-Services werden benötigt, um pseudonymisierte Daten über die Besucher der Website zu sammeln. Die Daten ermöglichen es uns, die Besucher besser zu verstehen und die Website zu optimieren.</p>
                    <h2>5 Essenziell</h2>
                    <p>Essential Services sind für die grundlegende Funktionalität der Website erforderlich. Sie enthalten nur technisch notwendige Services. Diesen Services kann nicht widersprochen werden.</p>
                </div>
            </div>
            <div className="flex gap end wrap">
                {
                    useIsMobile() ?
                        <>
                            <Button title="Alle akzeptierren" big nowrap fullWidth onClick={() => handleButtonClick("all")} />
                            <Button title="Nur notwendige" big nowrap fullWidth type="blue-border" onClick={() => handleButtonClick("some")} />
                        </> :
                        <>
                            <Button title="Nur notwendige" big nowrap type="transparent" onClick={() => handleButtonClick("some")} />
                            <Button title="Alle akzeptieren" big nowrap onClick={() => handleButtonClick("all")} />
                        </>
                }
            </div>
            <div className="flex gap wrap">
                <Link to="/imprint">
                    Impressum
                </Link>
                <Link to="/terms">
                    AGBs
                </Link>
                <Link to="/privacy">
                    Datenschutzerklärung
                </Link>
            </div>
        </Modal>
    );
};

export default Cookies;
import "./Header.css";
import LogoNormal from "../assets/logo/logo_normal.png";
import LogoBlack from "../assets/logo/logo_black.png";
import SettingsLogo from "../assets/overflow/settings_logo.png";
import Button from "./Button";
import { Link } from "react-router-dom";
import { getAccessToken } from "../utils/WebStorage";
import React, { useEffect, useState } from "react";
import { accessTokenIsValid } from "../utils/Permission";

/**
 * header
 * @param onlyTopBar show only the top bar
 * @param title title (add <br> for line break)
 * @param bigLogo big logo or small logo
 * @param settings settings Icon or logo
 * @param buttonText button title
 * @param buttonTo where button should redirect
 * @param buttonDefaultPage if button is part of a default page (e.g. all pages where a login is not required)
 * @param noMargin remove margin
 * @return header component
 */
function Header(
    props: Readonly<{
        onlyTopBar?: boolean,
        title?: string,
        bigLogo?: boolean,
        settings?: boolean,
        buttonText?: string,
        buttonTo?: string,
        buttonClick: (click: boolean) => void,
        buttonDefaultPage?: boolean,
        subtitle?: string,

        className?: string,
        [key: string]: any
    }>
) {
    const {onlyTopBar, subtitle, buttonDefaultPage, buttonClick, title, bigLogo, settings, buttonText, buttonTo, className, ...overflowProps} = props;
    
    const accessToken = getAccessToken();
    
    const [hasPermisison, setHasPermission] = useState(false);
    useEffect(() => { //check if client joined a game
        const fetchData = async () => {
            const hasPermission = await accessTokenIsValid();
            setHasPermission(hasPermission);
        };

        fetchData();
    }, []);
    
    return (
        <div className={`header w-100 flex center items-center column mt-5 ${onlyTopBar ? "mb-5" : "mb-3"} ${className || ""}`} {...overflowProps}>
            <div className="top-bar">
                <Link to={settings ? "/settings" : "/"}>
                    <img src={settings ? SettingsLogo : LogoBlack} alt="logo" width={40} height={40} />
                </Link>

                {buttonDefaultPage && accessToken !== null && hasPermisison ?
                    <Button title="Dashboard" type="transparent" big to="/dashboard" onClick={() => buttonClick(true)} /> :
                    buttonText !== undefined && <Button title={buttonText} type="transparent" big to={buttonTo} onClick={() => buttonClick(true)} />
                }
            </div>
            
            {
                !onlyTopBar &&
                <div className="banner">
                    <div className="flex column gap">
                        {title && <h1 className="title">{replaceBrTag(title)}</h1>}
                        {subtitle !== undefined && <p className="subtitle">{subtitle}</p>}
                    </div>

                    <img src={LogoNormal} alt="logo" width={bigLogo ? 190 : 130} height={bigLogo ? 190 : 130} />
                </div>
            }
        </div>
    );

    function replaceBrTag(text: string) {
        return text.split('<br>').map((item: string, index: number) => (
            <React.Fragment key={index}>
                {item}
                {index < text.split('<br>').length - 1 && <br />}
            </React.Fragment>
        ));
    }
}

export default Header;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//const alphabeticRegex = /^[a-zA-ZäöüÄÖÜß\s]+$/;
//const decimalRegex = /[.].{3,}/;
const nameRegex = /^[a-zA-ZäöüÄÖÜß\s- ]+$/;
const fullRegex = /^[a-zA-ZäöüÄÖÜß0-9,.\-_:;+*#""!?$\u20AC\u00A7%&/\\()[\]=<>|^° ]+$/; //emojis wont work
const alphaNumericRegex = /^[0-9a-zA-Z]+$/;
const UUIDRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;

export function isGameCode(gameCode: string): string | null {
    if (gameCode === "") {
        return "Der Game-Code fehlt";
    };
    if (gameCode.length > 5) {
        return "Die Länge des Game-Codes ist zu groß";
    };
    if (gameCode.length < 5) {
        return "Die Länge des Game-Codes ist zu klein";
    };
    if (!alphaNumericRegex.test(gameCode)) {
        return "Der Game-Code enthält unzulässige Zeichen"
    };

    return null;
};

export function isUUID(uuid: string): string | null {
    if (uuid.length !== 36) {
        return "Die Länge UUID ist fehlerhaft";
    };
    if (!UUIDRegex.test(uuid)) {
        return "Die UUID enthält unzulässige Zeichen";
    };

    return null;
};

export function isEmail(email: string): string | null {
    if (email.length === 0) {
        return "Die E-Mail-Adresse fehlt";
    };
    if (!emailRegex.test(email)) {
        return "Die E-Mail-Adresse ist ungültig";
    };
    if (email.length > 100) {
        return "Die E-Mail-Adresse ist zu lang";
    };
    if (email.trim().length < 3) {
        return "Die E-Mail-Adresse ist zu kurz";
    };

    return null;
};

export function isName(name: string): string | null {
    if (name.length === 0) {
        return "Der Name fehlt"
    };
    if (!nameRegex.test(name)) {
        return "Der Name enthält unzulässige Zeichen";
    };
    if (name.length >= 50) {
        return "Der Name ist zu lang";
    };
    if (name.trim().length <= 1) {
        return "Der Name ist zu kurz";
    };

    return null;
};

export function isImage(image: string): string | null {
    if (image.length === 0) {
        return "Das Bild fehlt"
    };
    if (!fullRegex.test(image)) {
        return "Das Bild enthält unzulässige Zeichen";
    };
    if (image.length >= 200) {
        return "Der Bild Name ist zu lang";
    };
    if (image.trim().length <= 1) {
        return "Der Bild Name ist zu kurz";
    };

    return null;
};

export function isAddress(address: string): string | null {
    if (address.length === 0) {
        return "Die Addresse fehlt"
    };
    if (!fullRegex.test(address)) {
        return "Die Adresse enthält unzulässige Zeichen";
    };
    if (address.length >= 200) {
        return "Die Adresse ist zu lang";
    };
    if (address.trim().length <= 1) {
        return "Die Adresse ist zu kurz";
    };

    return null;
};

export function isToken(token: string): string | null {
    if (token.trim().length <= 0) {
        return "Der token fehlt";
    };
    if (token.length > 500) {
        return "Der token ist zu lang";
    };

    return null;
};

export function isSessionID(sessionID: string): string | null {
    if (sessionID.trim().length !== 50) {
        return "Die Session ID länge ist unzulässig";
    };
    if (!alphaNumericRegex.test(sessionID)) {
        return "Die Session ID enthält ungültige Zeichen";
    };

    return null;
};
import "./Terms.css";
import Header from "../components/Header";

function Terms() {
    return (
        <>
            <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="w-m flex column gap">
                <h1 className="title">AGBs</h1>
                <p>Diese Webseite wird weder geschäftlich noch kommerziell genutzt. Deswegen sind keine AGBs erforderlich.</p>
            </div>
        </>
    );
};

export default Terms;
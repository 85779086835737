/**
 * get status code from database request
 * @param request database request
 * @returns status integer
 */
export function getStatusCode(request: any): number {
    if (request.code === "ERR_NETWORK") { //network error
        return 504;
    };
    
    if (request.status >= 200 && request.status < 300) { //success range
        return request.status;
    };
    
    return request.response.status; //error & redirection range
};
import { getAccessToken, getFirstLogin } from "../utils/WebStorage";
import { useEffect, useState } from "react";
import Tour from "../components/Tour";
import "./Dashboard.css";
import JoinContainer from "../components/JoinContainer";
import { retrieveService } from "../services/account.service";
import { getStatusCode } from "../utils/DBRequestProcessing";
import GameInterface from "../components/GameInterface";
import { editGameService } from "../services/game.service";
import LoadingSymbol from "../components/LoadingSymbol";
import TextExpandCollapse from "../components/TextExpandCollapse";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();
    
    const [loading, setLoading] = useState(false);
    const [joined, setJoined] = useState(false);

    useEffect(() => { //check if client joined a game
        const fetchData = async () => {
            setLoading(true);
            await checkGame();
            setLoading(false);
        };

        fetchData();

        const interval = setInterval(async () => {
            checkGame();
        }, 1000); //every second
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    async function checkGame() {
        const request = await retrieveService(getAccessToken(), "game");
        const statusCode = getStatusCode(request);
        
        switch (statusCode) {
            case 200: setJoined(true); break;
            case 504: navigate("/errorpage?code=504"); break;
            case 500: navigate("/errorpage?code=500"); break;
            default: setJoined(false); break;
        }
    }

    return (
        <>
            <div className="dashboard">
                {getFirstLogin() && <Tour /> /* show on first login */}

                {
                    loading ? <LoadingSymbol className="mt-5" dark size={100} /> :
                        <>
                            {
                                joined ?
                                <GameInterface setJoined={setJoined} />
                                :
                                <JoinContainer />
                            }
                        </>
                }

                <div className="faq">
                    <h1>FAQ</h1>
                    <TextExpandCollapse openOnDefault title="Woher bekomme ich den Gamecode?" description="Einen Gamecode erhältst du, nachdem du das Spiel gestartet hast. Dieser wird in der Lobby auf der weißen Tafel angezeigt. Drücke auf die 'Neues Spiel'-Taste im Spiel, falls es Probleme mit dem Gamecode gibt." />
                    <TextExpandCollapse title="Der Gamecode konnte nicht gefunden werden" description="Wenn der Gamecode nicht gefunden werden konnte, könnte es daran liegen, dass es Probleme mit der Verbindung gibt. Stelle sicher, dass du mit dem Internet verbunden bist und eine Verbindung zum Server herstellen kannst." />
                    <TextExpandCollapse title="Welche VR-Brille eignet sich am besten?" description="Meta Quest 3" />
                    <TextExpandCollapse title="Wie passe ich die Anzahl an Fehlversuchen an?" description="Durch Klicken auf die Herzen kannst du die Anzahl der möglichen Fehlversuche anpassen." />
                </div>
            </div >
        </>
    );
};

export default Dashboard;

export async function leaveGame(setJoined: any, navigate: any) {
    const request = await editGameService(getAccessToken(), "uuid", "");
    const statusCode = getStatusCode(request);

    switch (statusCode) {
        case 200: setJoined(false); break;
        case 504: navigate("/errorpage?code=504"); break;
        default: navigate("/errorpage?code=500"); break;
    };
};
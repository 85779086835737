import { warningLog } from "../utils/ConsoleLog";
import { preventScroll } from "../utils/ScrollHelper";
import "./Modal.css";
import { ReactNode, useEffect } from "react";

/**
 * modal component
 * @param width modal window width
 * @param height modal window height
 * @param type modal type (options: white (default), black)
 * @return cookie component
 */
function Modal(
    props: Readonly<{
        width?: string;
        height?: string;
        type?: string;

        children?: ReactNode;
        className?: string;
        [key: string]: any
    }>
) {
    const { width, height, type, className, children, ...overflowProps } = props;
    
    useEffect(() => {
        preventScroll(true);
        return () => { preventScroll(false); };
    }, []); 
    
    let finalType = "white";
    switch(type) {
        case "": break; case undefined: break; case "white": break; //default
        case "black": finalType = "black"; break;
        default: warningLog(`Modal type ${type} unavailable`, "Available options (case-sensitive): white (default), black"); break;
    }

    return (
        <div className={`modal ${finalType}`} {...overflowProps}>
            <div style={{width: width || "", height: height || ""}} className={className || ""}>
                {children}
            </div>
        </div>
    );
}

export default Modal;
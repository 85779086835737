import "./NextButton.css";
import ArrowRight from "../assets/overflow/arrow_right.png";

/**
 * next button component
 * @param size button size in pixel
 * @param color color (default: dark gray)
 * @return next button component
 */
function NextButton(
    props: Readonly<{
        size?: number,
        color?: string,

        className?: string,
        style?: any,
        [key: string]: any
    }>
) {
    const { className, style, size, color, image, ...overflowProps } = props;

    const choosenSize = size === undefined ? "50px" : String(size) + "px";

    return (
        <div
            className={`next-button ${className || ""}`}
            style={{
                minWidth: choosenSize, maxHeight: choosenSize,
                minHeight: choosenSize, maxWidth: choosenSize,
                
                backgroundColor: color === undefined ? "#522152" : color,
                ...style
            }}

            {...overflowProps}>
            
            <img
                src={ArrowRight}
                alt="next"
                width={size === undefined ? "28px" : size / 1.7}
            />
        </div>
    );
}

export default NextButton;
import { useEffect } from "react";
import Button from "./Button";
import Modal from "./Modal";
import "./WarningModal.css";
import { preventScroll } from "../utils/ScrollHelper";

/**
 * warning modal
 * @param title title question
 * @param option1 option1
 * @param option2 option2 (if "undefined" then it is only one button)
 * @param choosenOption user decission (true = option1)
 * @returns warning modal component
 */
function WarningModal(
    props: Readonly<{
        title: string;
        option1: string;
        option2?: string;

        choosenOption: (choice: boolean) => void;

        className?: string;
        [key: string]: any;
    }>
) {
    const { title, option1, option2, choosenOption, className, ...overflowProps } = props;

    useEffect(() => {
        preventScroll(true);
        return () => { preventScroll(false); };
    }, []); 
    
    return (
        <Modal style={{zIndex: 5}}>
            <div className={`warning-modal ${className || ""}`} {...overflowProps}>
                <p>{title}</p>
                <div className="flex gap wrap center">
                    <Button title={option1} type="black" onClick={() => choosenOption(true)} />
                    {option2 !== undefined && <Button title={option2} type="transparent" onClick={() => choosenOption(false)} />}
                </div>
            </div>
        </Modal>
    );
}

export default WarningModal;
import "./Home.css";
import Header from "../components/Header";
import Button from "../components/Button";
import Step1 from "../assets/home/step_1.png";
import Step2 from "../assets/home/step_2.png";
import Step3 from "../assets/home/step_3.png";

function Home() {
    return (
        <div className="home">
            <div className="main">
                <div className="pattern">
                    <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />
                </div>
                
                <div className="top">
                    <h1 className="title">Room of Horror</h1>
                    <div className="flex column gap">
                        <p>Ein Bachelorpraxisprojekt von<br/>Kâan Turan & Mohammed El-Bachir</p>
                        <p className="flex gap-1 wrap center">Betreut durch <a href="https://www.heike-wiesner.de/">Prof. Dr. rer. Heike Wiesner</a></p>
                    </div>
                    <div className="flex gap wrap center">
                        <Button big title="Jetzt loslegen" to="/signup" />
                        <Button type="transparent" big title="Anmelden" to="/login" />
                    </div>
                        
                    <img src={require("../assets/home/animation.gif")} alt="preview animation" />
                </div>
            </div>

            <div className="content">
                <div className="w-100 flex gap-5 wrap mb-5 mt-5">
                    <div className="flex-1">
                        <img className="step-image left-step" src={Step1} alt="step 1" />
                    </div>
                    <div className="flex-1 flex column gap-3">
                        <h5>Schritt 1</h5>
                        <h1 style={{fontSize: "35px"}}>Gamecode eintragen</h1>
                        <p style={{fontSize: "23px"}}>Die Bedienung der Room of Horror-Anwendung ist ganz einfach. Starte zunächst die VR-Anwendung und gib den angezeigten Game-Code in das Web-Interface ein.</p>
                    </div>
                </div>

                <div className="w-100 flex gap-5 wrap mb-5 mt-5">
                    <div className="flex-1 flex column gap-3 order-mobile-1">
                        <h5>Schritt 2</h5>
                        <h1 style={{fontSize: "35px"}}>Spiel einrichten</h1>
                        <p  style={{fontSize: "23px"}}>Nachdem du dich mit dem Spiel verbunden hast, kannst du einige Spieleinstellungen vornehmen. Zum Beispiel kannst du die maximale Anzahl an Fehlversuchen oder individuelle Fehler konfigurieren.</p>
                    </div>
                    <div className="flex-1 order-mobile-2">
                        <img className="step-image right-step" src={Step2} alt="step 2" />
                    </div>
                </div>

                <div className="w-100 flex gap-5 wrap mb-5 mt-5">
                    <div className="flex-1">
                        <img className="step-image left-step" src={Step3} alt="step 3" />
                    </div>
                    <div className="flex-1 flex column gap-3">
                        <h5>Schritt 3</h5>
                        <h1 style={{fontSize: "35px"}}>Spiel starten</h1>
                        <p style={{fontSize: "23px"}}>Das war's schon! Nun kannst du den Spielfortschritt des Spielers verfolgen. Alle gefundenen Unstimmigkeiten, aber auch Fehlklicks, werden dir sichtbar im Web-Interface angezeigt.</p>
                        <Button title="Jetzt loslegen" to="/signup" big />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
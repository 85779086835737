import "./RunningGameInterface.css";
import { useIsMobile } from "../utils/Mobile";
import MistakeTarget from "../assets/overflow/mistake_target.png";
import Objective from "../assets/overflow/objective_target.png";
import ObjectiveFound from "../assets/overflow/objective_found_target.png";
import Room from "../assets/overflow/room.png";
import Heart from "../assets/overflow/heart.png";
import HeartActive from "../assets/overflow/heart_active.png";
import ProgressBar from "./ProgressBar";
import { errorContent } from "../utils/Data";

/**
 * running game inferface component
 * @param mistakeCoordinates mistake coordinate array
 * @param mistakeAmount max amount of mistakes
 * @param errorCoordinates all error gameObject coordinates
 * @param gameErrors all game error id's
 * @param foundErrors all "found" errors by player
 * @return component
 */
function RunningGameInterface(
    props: Readonly<{
        mistakeCoordinates: number[];
        mistakeAmount: number;

        errorCoordinates: number[];
        gameErrors: number[];

        foundErrors: number[];

        className?: string,
        [key: string]: any
    }>
) {
    const { mistakeCoordinates, mistakeAmount, errorCoordinates, gameErrors, foundErrors, className, ...overflowProps } = props;

    //general
    let mapSize = 10; //needs to be the same as "mapSize" inside the css-root
    let mapHorizontal = true; //turn map horizontal/vertical

    //mobile settings
    const isMobile = useIsMobile();
    if (isMobile) {
        mapSize = 8;
        mapHorizontal = false;
    }

    //frontend minimum/maximum coordinates
    //const minXFront = 0;
    //const minYFront = 0;
    const maxXFront = mapSize * 27;
    const maxYFront = mapSize * 59;

    //client game-engine minimum/maximum coordinates
    const minXEngine = -2.5;
    const minYEngine = -3.8;
    const maxXEngine = 2.9;
    const maxYEngine = 8;

    return (
        <div className={`running-game-interface ${className || ""}`} {...overflowProps}>
            
            <div className="flex column gap-3">
                <div className="flex wrap between w-100 gap items-center">
                    <p className="subtitle">Verbleibende Versuche</p>
                    <div className="hearts-left flex wrap gap-1">
                        {Array.from({ length: mistakeAmount }, (_, index) => (
                            <img
                                key={index}
                                src={index >= mistakeCoordinates.length ? HeartActive : Heart}
                                alt="heart"
                                width={25}
                                height={25}
                            />
                        ))}
                    </div>
                </div>

                <div className="flex wrap between w-100 gap items-center">
                    <p className="subtitle">Spielfortschritt ({(foundErrors.length * (1 / gameErrors.length) * 100).toFixed(0)}%)</p>
                    <ProgressBar progress={foundErrors.length * (1 / gameErrors.length)} />
                </div>
            </div>

            <div className="break-line" />
            
            {errorCoordinates.length === 0 && <p className="error">Bitte starte die VR Brille neu, die Initialisierung ist fehlgeschlagen</p>}
            
            <div className={`map-container ${mapHorizontal ? "" : "vertical"}`}>
                <div className={`map ${mapHorizontal ? "horizontal" : ""}`}>
                    {/* display all error objects */}
                    {Array.from({ length: errorCoordinates.length }, (value: any, index: number) => (
                        gameErrors.includes(errorCoordinates[index][0]) && (
                            <div key={index}>{
                                foundErrors.includes(errorCoordinates[index][0]) ?
                                    //found icon
                                    <img
                                        alt="target"
                                        className={`target ${isMobile ? "small" : ""}`}
                                        style={{
                                            bottom: calcMistakeCor(errorCoordinates[index][1], errorCoordinates[index][3])[1],
                                            left: calcMistakeCor(errorCoordinates[index][1], errorCoordinates[index][3])[0]
                                        }}
                                        width={50}
                                        height={50}
                                        src={ObjectiveFound}
                                    />
                                    :
                                    //not found icon
                                    <div
                                        className={`target ${isMobile ? "small" : ""}`}
                                        style={{
                                            bottom: calcMistakeCor(errorCoordinates[index][1], errorCoordinates[index][3])[1],
                                            left: calcMistakeCor(errorCoordinates[index][1], errorCoordinates[index][3])[0]
                                        }}
                                    >
                                        {/* <h2>{errorCoordinates[index][0] + 1}</h2> */}
                                        <img
                                            alt="target"
                                            width={50}
                                            height={50}
                                            src={Objective}
                                        />
                                    </div>
                            }</div>
                        )
                    ))}

                    {/* display all mistakes made by the player */}
                    {Array.from({ length: mistakeCoordinates.length }, (value, index) => (
                        <img
                            alt="target"
                            className={`target ${isMobile ? "small" : ""}`}
                            style={{
                                bottom: calcMistakeCor(mistakeCoordinates[index][0], mistakeCoordinates[index][2])[1],
                                left: calcMistakeCor(mistakeCoordinates[index][0], mistakeCoordinates[index][2])[0]
                            }}
                            width={50}
                            height={50}
                            src={MistakeTarget}
                            key={index}
                        />

                    ))}

                    <img
                        alt="room"
                        src={Room}
                    />
                </div>
            </div>

            <div className="flex w-100 gap mt column">
                <p className="subtitle">Gefundene Fehler</p>
                <div className="targets-container">
                    {Array.from({ length: gameErrors.length }, (value: number, index: number) => (
                        <div key={index} className={`${foundErrors.includes(Number(errorContent[gameErrors[index]][0])) && "found"}`}>
                            <img
                                src={errorContent[index][1]}
                                alt="error"
                                width={30}
                                height={30}
                            />
                            
                            <p>{errorContent[gameErrors[index]][0]}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

    function calcMistakeCor(x: number, y: number) {
        //normalize values from client (game-engine)
        const normXEngine = normalize(x, minXEngine, maxXEngine);
        const normYEngine = normalize(y, minYEngine, maxYEngine);

        //calculate frontend coordinates
        const EngineXToFrontX = normXEngine * maxXFront;
        const EngineYToFrontY = normYEngine * maxYFront;

        return [EngineXToFrontX, EngineYToFrontY];
    }

    //normalize value
    function normalize(x: number, min: number, max: number) {
        return (x - min) / (max - min);
    }
}

export default RunningGameInterface;
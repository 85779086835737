import "./ProgressBar.css";

/**
 * progress bar component
 * @param progress number between 0 and 1
 * @return page bar component
 */
function ProgressBar(
    props: Readonly<{
        progress?: number;

        className?: string;
        [key: string]: any;
    }>
) {
    const { progress, className, ...overflowProps } = props;

    return (
        <div
            className={`progress-bar ${className || ""}`}

            {...overflowProps}
        >
            <div className="bar" style={{width: progress === undefined ? 0 : progress * 250}} />
        </div>
    );
}

export default ProgressBar;
import { getCurrentDateTime } from "./DateTime";

/**
 * set access token into local storage
 * @param token access token
 */
export function setAccessToken(token: string) {
    localStorage.setItem("access_token", token);
};

/**
 * get access token from local storage
 * @returns access token
 */
export function getAccessToken(): string {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken === null || accessToken === "null") {
        return null
    };
    return accessToken;
};

/**
 * set last request time to current date-time in local storage
 */
export function setLastRequest() {
    localStorage.setItem("last_request", JSON.stringify(getCurrentDateTime()));
};

/**
 * get last request time from local storage
 * @returns last date time
 */
export function getLastRequest(): number {
    const lastRequest = localStorage.getItem("last_request");
    if (lastRequest === null) {
        return 0;
    };
    return Number(lastRequest);
};

/**
 * @param preference cookies preference
 * set cookies accepted boolean in local storage
 */
export function setCookiesPreference(preference: string) {
    localStorage.setItem("cookies_changed_date", JSON.stringify(getCurrentDateTime()));
    localStorage.setItem("cookies_preference", preference);
};

/**
 * get if cookies preference already choosen
 * @returns if cookies preference already choosen
 */
export function getCookiePreferenceChoosed(): boolean {
    const cookiesPreference = localStorage.getItem("cookies_changed_date");
    if (cookiesPreference === null) {
        return false;
    };
    return true;
};

/**
 * set options menu open status
 * @param open open
 */
export function setOptionsMenuOpen(open: boolean) {
    localStorage.setItem("options_menu_open", String(open));
};

/**
 * get options menu status
 * @returns open status
 */
export function getOptionsMenuOpen(): boolean {
    const optionsMenuStatus = JSON.parse(localStorage.getItem("options_menu_open"));
    if (optionsMenuStatus === null) {
        return null;
    };
    return Boolean(optionsMenuStatus)
};

/**
 * set first login
 * @param firstLogin set first login preference
 */
export function setFirstLogin(firstLogin: boolean) {
    localStorage.setItem("first_login", String(firstLogin));
};

/**
 * get first login
 * @return
 */
export function getFirstLogin(): boolean {
    const firstLogin = JSON.parse(localStorage.getItem("first_login"))
    if (firstLogin === null || firstLogin) {
        return true;
    }
    return false;
};

export function setHealthAmount(amount: number) {
    localStorage.setItem("health_amount", String(amount));
}

export function getHealthAmount(): number | null {
    const amount = localStorage.getItem("health_amount");
    if (amount === null || amount === "null") {
        return null;
    }
    return Number(amount);
}

export function setRandomErrorAmount(amount: number) {
    localStorage.setItem("random_error_amount", String(amount));
}

export function getRandomErrorAmount(): number | null {
    const amount = localStorage.getItem("random_error_amount");
    if (amount === null || amount === "null") {
        return null;
    }
    return Number(amount);
}
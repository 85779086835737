import { useEffect, useRef, useState } from "react";
import "./GameCodeInput.css";

/**
 * game code input fields component
 * @return component
 */
function GameCodeInput(
    props: Readonly<{
        onGameCodeChange?: (code: string) => void;
        handleEnterPress?: (press: boolean) => void;
        error?: boolean;

        className?: string;
        [key: string]: any;
    }>
) {
    const { className, error, onGameCodeChange, handleEnterPress, ...overflowProps } = props;

    const [gameCode, setGameCode] = useState("#####");

    const inputRefs = useRef([]);

    const updateGameCode = (newValue: string, position: number) => {
        let newGameCodeArray = gameCode.split("");
        newGameCodeArray[position] = newValue;
        let newGameCode = newGameCodeArray.join("");
        setGameCode(newGameCode);
        onGameCodeChange(newGameCode);
    }

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 5);
    }, []);

    return (
        <div className={`game-code-input ${className || ""}`} {...overflowProps}>
            {[...Array(5)].map((_, index) => (
                <input
                    key={index}
                    ref={(e) => (inputRefs.current[index] = e)}
                    onChange={(e) => {
                        if (e.target.value.length > 1) {
                            (e.target as HTMLInputElement).value = e.target.value[1];
                        } 

                        if (e.target.value && index < 4) {
                            inputRefs.current[index + 1].focus();
                        }

                        if (index === 4) {
                            e.target.blur();
                        }

                        updateGameCode(e.target.value, index);
                    }}
                    onClick={(e) => {
                        (e.target as HTMLInputElement).value = "";
                    }}
                    maxLength={2}
                    className={`${error && "error"}`}
                    onKeyDown={(event: any) => {
                        if (event.key === "Enter") {
                            if (index === 4 && event.target.value !== "") {
                                handleEnterPress(true);
                            } else if (event.target.value !== "") {
                                inputRefs.current[index + 1].focus();
                            }
                        } else if (event.key === "Backspace" && index !== 0) {
                            if (index === 4 && event.target.value !== "") {
                                event.target.value = "";
                            } else {
                                inputRefs.current[index - 1].focus();
                            }
                        }
                    }}
                />
            ))}
        </div>
    );
}

export default GameCodeInput;
import { useEffect, useState } from "react";
import Button from "./Button";
import ContentContainer from "./ContentContainer";
import "./JoinContainer.css";
import { joinGameService } from "../services/game.service";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../utils/WebStorage";
import { isGameCode } from "../utils/InputCheckHelper";
import LoadingSymbol from "./LoadingSymbol";
import GameCodeInput from "./GameCodeInput";
import InfoCircle from "./InfoCircle";
import Header from "./Header";

/**
 * join container
 * @return join container component
 */
function JoinContainer(
    props: Readonly<{
        className?: string;
        [key: string]: any;
    }>
) {
    const { className, ...overflowProps } = props;

    const navigate = useNavigate();

    const [success, setSuccess] = useState(false);
    const [gameCode, setGameCode] = useState("");
    const [error, setError] = useState("");

    async function handleConnect() {
        setError("");

        const checkGameCode = isGameCode(gameCode);
        if (checkGameCode === null) {
            const request = await joinGameService(getAccessToken(), gameCode);
            const statusCode = getStatusCode(request);

            if (statusCode === 200) {
                setSuccess(true);
            } else if (statusCode === 404) {
                setError("Gamecode nicht gefunden");
            } else {
                navigate("/errorpage?code=500");
            }
        } else {
            setError(checkGameCode);
        }
    };

    useEffect(() => {
        setError("");
    }, [gameCode]);
    
    const handleEnterPress = () => {
        handleConnect();
        setError("");
    };

    return (
        <>
            <Header title="Spiel suchen" settings buttonClick={() => {}} />

            <div className={`join-container ${className || ""}`} {...overflowProps}>
                <ContentContainer className="flex column items-center gap">
                    {
                        success ?
                        <div className="flex column gap items-center">
                            <LoadingSymbol dark size={100} />
                            <h2>Suche Spiel...</h2>
                        </div>
                        :
                        <>
                            <div className="w-100 flex gap-1">
                                <p>Gamecode</p>
                                <InfoCircle text="Der im Spiel angezeigte Gamecode muss hier eingetragen werden" />
                            </div>
                            
                            <GameCodeInput onGameCodeChange={setGameCode} handleEnterPress={handleEnterPress} error={error !== "" && true} />
                            {
                                error !== "" &&
                                <div className="flex w-100 end">
                                    <p className="error">{error}</p>
                                </div>
                            }
                            <Button title="Verbinden" fullWidth big onClick={() => handleConnect()} />
                        </>
                    }
                </ContentContainer>
            </div>
        </>
    );
}

export default JoinContainer;
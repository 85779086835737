import { ReactNode, useState } from "react";
import "./TextExpandCollapse.css";

/**
 * @param title title text
 * @param description description text
 * @param openOnDefault if the description container should be open by default (when children used, automatically enabled)
 * @returns 
 */
function TextExpandCollapse(
    props: Readonly<{
        title?: string,
        description?: string,
        openOnDefault?: boolean,
        children?: ReactNode
    }>
) {
    const {title, description, children, openOnDefault} = props;
    
    const [active, setActive] = useState(openOnDefault);

    return (
        <div className='tec-container flex gap-1 column'>
            {title && <h2 className='title' onClick={() => setActive(!active)}>{title}</h2>}
            <div className={active ? 'active' : ''}>
                <h4>{description || ''}</h4>
                {children}
            </div>
        </div>
    )
}
export default TextExpandCollapse;
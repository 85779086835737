import "./ErrorBox.css";
import DefaultImage from "../assets/overflow/gear_black.png";
import Checkmark from "../assets/overflow/checkmark_individual_error.png";

/**
 * error box component
 * @param image image (not path);
 * @param title title
 * @param clicked if clicked
 * @param selected if selected
 * @return error box component
 */
function ErrorBox(
    props: Readonly<{
        image?: any,
        title: string,
        selected?: boolean,

        clicked?: () => void,

        style?: any,
        className?: string,
        [key: string]: any
    }>
) {
    const { image, title, selected, clicked, style, className, ...overflowProps } = props;

    return (
        <div className={`error-box ${className || ""} ${selected ? "selected" : ""}`} {...overflowProps} onClick={clicked}>
            <img
                src={image === undefined ? DefaultImage : image}
                alt="error box"
                width={30}
                height={30}
            />
            
            <p>{title}</p>

            <img className={`${selected && "active"}`} src={Checkmark} alt="checkmark" width={30} height={30} />
        </div>
    );
}

export default ErrorBox;
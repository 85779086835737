import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTopOnReload() { //scroll on top on page render/reload
    const location = useLocation(); //if url changes
    
    useEffect(() => { //will render on location change or reload
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};

/**
 * enable/disable scrolling
 * @param preference change scroll state
 */
export function preventScroll(preference: boolean) {
    if (preference) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "scroll";
    };
};
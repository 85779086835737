import "./LoadingSymbol.css";

/**
 * loading symbol
 * @param size width & height in pixel (default: 25px)
 * @param dark make the symbol color dark
 * @return loading symbol component
 */
function LoadingSymbol(
    props: Readonly<{
        size?: number,
        dark?: boolean,

        className?: string,
        [key: string]: any
    }>
) {
    const {size, dark, className, ...overflowProps} = props;

    let loadingSize = "25px"; //default
    if (size !== undefined) {
        loadingSize = `${size}px`;
    }

    return (
        <div
            className={`loading-symbol ${className} ${dark ? "dark" : ""}`}
            style={{height: loadingSize, width: loadingSize}}

            {...overflowProps}
        />
    );
}

export default LoadingSymbol;
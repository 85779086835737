import "./InfoCircle.css";
import { useState } from "react";

/**
 * info circle
 * @param text info text on hover (maximal 330 symbols)
 * @return info circle component
 */
function InfoCircle(
    props: Readonly<{
        text: string,

        className?: string,
        [key: string]: any
    }>
) {
    const { text, className, ...overflowProps } = props;
    const [showMessage, setShowMessage] = useState(false);

    return (
        <div
            className={`info-circle ${showMessage ? "show" : ""} ${className || ""}`}
            onTouchStart={() => setShowMessage(!showMessage)} //will only work on touch devices

            {...overflowProps}
        >
            <p>i</p>
            
            <div className="content">
                <div />
                <div>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default InfoCircle;
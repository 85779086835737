import "./PageBar.css";

/**
 * page bar component
 * @param page page number (starting at 1)
 * @param totalPages total page amount
 * @return page bar component
 */
function PageBar(
    props: Readonly<{
        page: number;
        totalPages: number;

        setCurrentPage: (page: number) => void;

        className?: string;
        [key: string]: any;
    }>
) {
    const { page, totalPages, setCurrentPage, className, ...overflowProps } = props;

    return (
        <div
            className={`page-bar ${className || ""}`}

            {...overflowProps}
        >
           {[...Array(totalPages)].map((v, i) => (
                <div
                    key={i}
                    className={`${i === page - 1 && "selected"}`}
                    onClick={() => setCurrentPage(i + 1)}
                />
            ))}
        </div>
    );
}

export default PageBar;
import "./HeartBar.css";
import Heart from "../assets/overflow/heart.png";
import HeartActive from "../assets/overflow/heart_active.png";
import { useState } from "react";
import { getHealthAmount, setHealthAmount } from "../utils/WebStorage";

/**
 * hear bar component
 * @param totalAmount total amount of hearts
 * @param onHeartChange if user changes heart amount
 * @return hear bar component
 */
function HeartBar(
    props: Readonly<{
        totalAmount: number,
        onHeartChange?: (amount: number) => void;

        className?: string,
        [key: string]: any
    }>
) {
    const { totalAmount, className, ...overflowProps } = props;

    const healthAmount = getHealthAmount();
    if (healthAmount === null) {
        window.location.reload();
        setHealthAmount(3); //set default value
    }

    const [selectedAmount, setSelectedAmount] = useState(healthAmount);

    return (
        <div className={`heart-bar ${className || ""}`} {...overflowProps}>
            {Array.from({ length: totalAmount }, (_, index) => (
                <img
                    key={index}
                    src={index >= selectedAmount ? Heart : HeartActive}
                    alt="heart"
                    width={25}
                    height={25}
                    onClick={() => {
                        setSelectedAmount(index + 1);
                        setHealthAmount(index + 1); //set inside web-storage
                    }}
                />
            ))}
        </div>
    );
}

export default HeartBar;